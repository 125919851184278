// composables/useMeta.js
export const useMeta = (options) => {
  const author = "Brains Disruptive";
  const website = "https://www.dny.ai/";
  // const twitter = "@artvr";
  const keywords = "Dny AI, virtual reality, festival";
  const image = "https://www.dny.ai/images/og_dny-ai.jpg";
  const sitename = "Dny AI | 2024";

  useHead({
    htmlAttrs: {
      lang: "cs",
    },
    title: options.title,
    meta: [
      //general
      { charset: "utf-8" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, viewport-fit=cover",
      },
      {
        name: "msapplication-TileColor",
        content: "#da532c",
      },
      {
        name: "theme-color",
        content: '"#ffffff',
      },
      { name: "format-detection", content: "telephone=no" },
      // SEO Meta Tags
      {
        name: "description",
        content: options.description,
      },
      {
        name: "keywords",
        content: options.keywords || keywords,
      },
      {
        name: "author",
        content: options.author || author,
      },
      // Open Graph Meta Tags
      {
        property: "og:title",
        content: options.title,
      },
      {
        property: "og:description",
        content: options.description,
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:url",
        content: options.website || website,
      },
      {
        property: "og:image",
        content: options.image || image,
      },
      {
        hid: "og:site_name",
        property: "og:site_name",
        content: sitename,
      },
      // Twitter Card Meta Tags
      {
        name: "twitter:card",
        content: "summary_large_image",
      },
      {
        name: "twitter:title",
        content: options.title,
      },
      {
        name: "twitter:description",
        content: options.description,
      },
      {
        name: "twitter:image",
        content: options.image || image,
      },
      // {
      //   name: "twitter:site",
      //   content: options.twitter || twitter,
      // },
    ],
  });
};
